<template>
  <mcr-wiki :mdContent="mdContent" :title="title" :pic="pic" :wikiUrl="wikiUrl" :loading="loading" />
</template>

<script>
const McrWiki = () => import('@common/elements/glossary/mcrWiki');

export default {
  name: 'mcr-remote-wiki',
  props: ['source', 'id'],
  components: {
    McrWiki,
  },
  data() {
    return {
      mdContent: '',
      title: '',
      pic: '',
      wikiUrl: '',
      loading: false,
    };
  },
  mounted() {
    let that = this;
    this.loading = true;
    this.network.wiki
      .getArticleRaw({
        slug: this.id,
      })
      .then(res => {
        if (res && res.content && res.content !== '') {
          that.mdContent = res.content;
          that.title = res.title;
          that.pic = res.image;
          if (res.attributes) {
            for (let attribute of res.attributes) {
              if (attribute.attribute_name === 'Link') {
                that.wikiUrl = attribute.attribute_value;
              }
            }
          }
        }
      })
      .catch(error => {})
      .finally(() => {
        this.loading = false;
      });
  },
  method: {},
};
</script>
<style scoped lang="scss"></style>
